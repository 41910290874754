import React from 'react';
import { useQuery } from '@apollo/client';
import moment from 'moment';

import { GET_HOUR_COUNT } from '../../../graphql/queries';
import useUnclockedTime from '../../../hooks/useUnclockedTime';
import useVisibilityChange from '../../../hooks/useVisibilityChange';

function TimeForDay({ userId, date }) {
  const unclockedTime = useUnclockedTime();
  const { loading, data, refetch } = useQuery(GET_HOUR_COUNT, {
    variables: {
      userId,
      startDate: moment(date).startOf('day').valueOf(),
      endDate: moment(date).endOf('day').valueOf()
    }
  });

  useVisibilityChange(refetch);

  if (loading) return <p>Loading...</p>;

  const includeUnclockedTime = moment(date).isSame(moment(), 'day');
  const timeFromQuery = data.hourCount.totalMilliseconds;
  const timeForDay = includeUnclockedTime
    ? moment.duration(timeFromQuery + unclockedTime)
    : moment.duration(timeFromQuery);

  return (
    <p>
      {`Total time for today: ${timeForDay.hours()} hours ${timeForDay.minutes()} minutes ${timeForDay.seconds()} seconds`}
    </p>
  );
}

export default TimeForDay;
