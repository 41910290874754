import { useState, useEffect } from 'react';
import moment from 'moment';

import useLastRecord from './useLastRecord';

function useUnclockedTime() {
  const { loading, data } = useLastRecord();
  const [now, setNow] = useState(moment());

  useEffect(() => {
    const id = setTimeout(() => setNow(moment()), 1000);
    return () => {
      clearTimeout(id);
    };
  });

  if (loading) return 0;

  if (!data.lastRecord.timeOut) {
    const timeIn = data.lastRecord.timeIn;
    return now.diff(timeIn);
  }

  return 0;
}

export default useUnclockedTime;
