import React, { useContext, createContext, useReducer } from 'react';
import moment from 'moment';

function reducer(state, { type, payload }) {
  switch (type) {
    case 'weekly': {
      const curStart = state.weekly.start;
      const curEnd = state.weekly.end;
      return {
        ...state,
        weekly: {
          start: payload.start || curStart,
          end: payload.end || curEnd
        }
      };
    }
    case 'user': {
      const curStart = state.user.start;
      const curEnd = state.user.end;
      return {
        ...state,
        user: {
          start: payload.start || curStart,
          end: payload.end || curEnd
        }
      };
    }
    case 'daily': {
      const curStart = state.daily.start;
      const curEnd = state.daily.end;
      return {
        ...state,
        daily: {
          start: payload.start || curStart,
          end: payload.end || curEnd
        }
      };
    }
    default: {
      console.error('useReportDates: unknown type given to reducer');
    }
  }
}

const ReportDatesContext = createContext();

function useReportDates() {
  const context = useContext(ReportDatesContext);
  if (!context) {
    throw new Error('useReportDates must be used within a ReportDatesProvider');
  }
  return context;
}

function ReportDatesProvider({ children }) {
  const initialState = {
    weekly: {
      start: moment().startOf('week').toDate(),
      end: moment().endOf('week').toDate()
    },
    user: {
      start: moment().startOf('day').subtract(30, 'days').toDate(),
      end: moment().endOf('day').toDate()
    },
    daily: {
      start: moment().startOf('day').toDate(),
      end: moment().endOf('day').toDate()
    }
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  const reportDatesValue = {
    dates: state,
    changeDates: dispatch
  };

  return (
    <ReportDatesContext.Provider value={reportDatesValue}>
      {children}
    </ReportDatesContext.Provider>
  );
}

export default useReportDates;
export {
  ReportDatesProvider
};