import React from 'react';
import {
  Switch,
  Route,
  Redirect
} from 'react-router-dom';

import Dashboard from './Dashboard/Dashboard';
import Reports from './Reports/Reports';
import Administration from './Administration/Administration';

function Switches({ isAuthenticated, user }) {
  const isAdmin = user
    ? user['https://cthomes/roles'].includes('Time Clock Admin')
    : false;

  const UserSwitch = () => {
    return (
      <Switch>
        <Redirect exact from="/" to="/dashboard" />
        <Route exact path="/dashboard">
          <Dashboard />
        </Route>
        <Route path="/reports">
          <Reports />
        </Route>
        <Redirect to="/dashboard" />
      </Switch>
    );
  };

  const AdminSwitch = () => {
    return (
      <Switch>
        <Redirect exact from="/" to="/dashboard" />
        <Route exact path="/dashboard">
          <Dashboard />
        </Route>
        <Route path="/reports">
          <Reports />
        </Route>
        <Route path="/admin">
          <Administration />
        </Route>
        <Redirect to="/dashboard" />
      </Switch>
    );
  };

  const AnonSwitch = () => {
    return (
      <Switch>
        <Redirect from="/" to="/" />
      </Switch>
    );
  };

  if (!isAuthenticated) return <AnonSwitch />;
  if (isAdmin) return <AdminSwitch />;

  return <UserSwitch />;
}

export default Switches;
