import React from 'react';
import {
  Switch,
  Route,
  Redirect,
  useRouteMatch
} from 'react-router-dom';

import { LastRecordProvider } from '../../hooks/useLastRecord';
import { ReportDatesProvider } from '../../hooks/useReportDates';
import WeeklyReport from './WeeklyReport/WeeklyReport';
import UserReport from './UserReport/UserReport';
import DailyReport from './DailyReport/DailyReport';

function Reports() {
  const { path } = useRouteMatch();

  return (
    <LastRecordProvider>
      <ReportDatesProvider>
        <Switch>
          <Route exact path={`${path}/weekly`}>
            <WeeklyReport />
          </Route>
          <Route exact path={`${path}/user`}>
            <UserReport />
          </Route>
          <Route exact path={`${path}/daily/:date`}>
            <DailyReport />
          </Route>
          <Redirect to={`${path}/weekly`} />
        </Switch>
      </ReportDatesProvider>
    </LastRecordProvider>
  );
}

export default Reports;
