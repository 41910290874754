import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache
} from '@apollo/client';

import App from './components/App';

const client = new ApolloClient({
  uri: 'https://cth-time-clock-api.herokuapp.com/graphql',
  cache: new InMemoryCache()
});

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain="cthomes.auth0.com"
      clientId="o6keSaUypXPpjN5OHSPCBvXa1Tz2uKaM"
      redirectUri={window.location.origin}
    >
      <ApolloProvider client={client}>
        <Router>
          <App />
        </Router>
      </ApolloProvider>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
