import React from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

import UserDatePickers from './UserDatePickers';
import UserDateButtons from './UserDateButtons';
import UserReportTable from './UserReportTable';

function UserReport() {
  return (
    <Container fluid="md">
      <Breadcrumb>
        <Breadcrumb.Item
          linkAs={Link}
          linkProps={{ to: '/reports/weekly' }}
        >
          Weekly Report
        </Breadcrumb.Item>
        <Breadcrumb.Item active>User Report</Breadcrumb.Item>
      </Breadcrumb>
      <UserDatePickers />
      <UserDateButtons />
      <UserReportTable />
    </Container>
  );
}

export default UserReport;
