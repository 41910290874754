import React from 'react';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';

import useReportDates from '../../../hooks/useReportDates';

function UserDateButtons() {
  const { changeDates } = useReportDates();

  function onClick(numDays) {
    changeDates({
      type: 'user',
      payload: {
        start: moment().startOf('day').subtract(numDays, 'days').toDate(),
        end: moment().endOf('day').toDate()
      }
    });
  }

  return (
    <Row className="justify-content-center mb-3">
      <ButtonGroup>
        <Button variant="outline-primary" onClick={() => onClick(30)}>30 days</Button>
        <Button variant="outline-primary" onClick={() => onClick(90)}>90 days</Button>
        <Button variant="outline-primary" onClick={() => onClick(180)}>180 days</Button>
        <Button variant="outline-primary" onClick={() => onClick(365)}>1 year</Button>
      </ButtonGroup>
    </Row>
  );
}

export default UserDateButtons;
