import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';

function Navigation() {
  const {
    isLoading,
    isAuthenticated,
    user,
    loginWithRedirect,
    logout
  } = useAuth0();

  return (
    isLoading ? (
      <div>Loading...</div>
    ) : (
        <Navbar expand="md">
          <Navbar.Brand as={Link} to="/">Time Clock</Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse>
            {isAuthenticated ? (
              user['https://cthomes/roles'].includes('Time Clock Admin') ? (
                <AdminLinks />
              ) : (
                  <UserLinks />
                )
            ) : (
                <Nav className="mr-auto" />
              )}
            {isAuthenticated ? (
              <Button onClick={logout}>Log Out</Button>
            ) : (
                <Button onClick={loginWithRedirect}>Log In</Button>
              )}
          </Navbar.Collapse>
        </Navbar>
      )
  );
}

function UserLinks() {
  return (
    <Nav className="mr-auto">
      <Nav.Link as={NavLink} to="/dashboard" exact>Home</Nav.Link>
      <Nav.Link as={NavLink} to="/reports">Reports</Nav.Link>
    </Nav>
  );
}

function AdminLinks() {
  return (
    <Nav className="mr-auto">
      <Nav.Link as={NavLink} to="/dashboard" exact>Home</Nav.Link>
      <Nav.Link as={NavLink} to="/reports">Reports</Nav.Link>
      <Nav.Link as={NavLink} to="/admin">Admin</Nav.Link>
    </Nav>
  );
}

export default Navigation;
