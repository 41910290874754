import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Table from 'react-bootstrap/Table';

import useReportDates from '../../../hooks/useReportDates';
import useUnclockedTime from '../../../hooks/useUnclockedTime';
import TimeForDay from './TimeForDay';

function UserReportTable() {
  const { dates: { user: dates } } = useReportDates();
  const unclockedTime = useUnclockedTime();

  function getDatesForTable(startDate, endDate) {
    const dateArray = [];
    let startMoment = moment(startDate);
    let endMoment = moment(endDate);

    while (startMoment <= endMoment) {
      dateArray.push({
        string: startMoment.format('MM/DD/YYYY'),
        date: startMoment.toDate().valueOf()
      });
      startMoment = startMoment.add(1, 'day');
    }

    return dateArray.sort((a, b) => a.date < b.date ? 1 : -1);
  }

  const datesForTable = getDatesForTable(dates.start, dates.end);

  return (
    <Table>
      <thead>
        <tr>
          <th>Date</th>
          <th>Time</th>
        </tr>
      </thead>
      <tbody>
        {datesForTable.map((date, index) => (
          <tr key={date.date}>
            <td>
              <Link to={`/reports/daily/${date.date}`}>
                {date.string}
              </Link>
            </td>
            <td>
              {index === 0 &&
                <TimeForDay
                  date={date.date}
                  unclockedTime={unclockedTime}
                />}
              {index !== 0 &&
                <TimeForDay
                  date={date.date}
                  unclockedTime={0}
                />}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

export default UserReportTable;
