import gql from 'graphql-tag';

const GET_LAST_RECORD = gql`
  query GetLastRecord($userId: String) {
    lastRecord(userId: $userId) {
      _id
      timeIn
      timeOut
    }
  }
`;

const GET_RECORDS = gql`
  query GetRecords(
    $userId: String,
    $startDate: Date,
    $endDate: Date
  ) {
    records(
      userId: $userId,
      startDate: $startDate,
      endDate: $endDate
    ) {
      _id
      timeIn
      timeOut
    }
  }
`;

const GET_HOUR_COUNT = gql`
  query GetHourCount(
    $userId: String,
    $startDate: Date,
    $endDate: Date
  ) {
    hourCount(
      userId: $userId,
      startDate: $startDate,
      endDate: $endDate
    ) {
      totalMilliseconds
    }
  }
`;

export {
  GET_LAST_RECORD,
  GET_RECORDS,
  GET_HOUR_COUNT
};