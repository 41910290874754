import React, {
  useContext,
  createContext
} from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@apollo/client';

import { GET_LAST_RECORD } from '../graphql/queries';
import useVisibilityChange from './useVisibilityChange';

const LastRecordContext = createContext();

function useLastRecord() {
  const context = useContext(LastRecordContext);
  if (!context) {
    throw new Error('useLastRecord must be used within a LastRecordProvider');
  }
  return context;
}

function LastRecordProvider({ children }) {
  const { user } = useAuth0();
  const userId = user['https://cthomes/mongo_uid'];
  const { loading, data, refetch } = useQuery(GET_LAST_RECORD, {
    variables: { userId }
  });

  useVisibilityChange(refetch);

  return (
    <LastRecordContext.Provider value={{ loading, data }}>
      {children}
    </LastRecordContext.Provider>
  );
}

export default useLastRecord;
export {
  LastRecordProvider
};
