import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import moment from 'moment';
import Container from 'react-bootstrap/Container';

import { LastRecordProvider } from '../../hooks/useLastRecord';
import ToggleStatusButton from './ToggleStatusButton';
import CurrentStatus from './CurrentStatus';
import DashboardTable from './DashboardTable';
import TimeForDay from './TimeForDay';

function Dashboard() {
  const { user } = useAuth0();

  return (
    <LastRecordProvider>
      <Container fluid="md">
        <h1 className="text-center">Welcome, {user.name}</h1>
        <ToggleStatusButton />
        <CurrentStatus />
        <h4>{moment().format('dddd, MMMM D, YYYY')}</h4>
        <DashboardTable />
        <TimeForDay />
      </Container>
    </LastRecordProvider>
  );
}

export default Dashboard;
