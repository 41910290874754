import React from 'react';
import moment from 'moment';

import useLastRecord from '../../hooks/useLastRecord';

function CurrentStatus() {
  const { loading, data } = useLastRecord();

  if (loading) return <p>Loading...</p>;

  return (
    !data.lastRecord.timeOut ? (
      <p className="text-center">
        {`Clocked in at ${moment(data.lastRecord.timeIn).format('h:mm A M/D/YY')}`}
      </p>
    ) : null
  );
}

export default CurrentStatus;
