import React from 'react';
import {
  Switch,
  Route,
  Redirect,
  useRouteMatch
} from 'react-router-dom';
import Container from 'react-bootstrap/Container';

import { ReportDatesProvider } from '../../hooks/useReportDates';

function Administration() {
  const { path } = useRouteMatch();

  return (
    <ReportDatesProvider>
      <Container fluid="md">
        <h1 className="text-center">Time Clock Administration</h1>
      </Container>
      <Switch>
        <Route exact path={`${path}/weekly`}>
          <div>Weekly</div>
          {/* <WeeklyReport /> */}
        </Route>
        <Route exact path={`${path}/user`}>
          {/* <UserReport /> */}
        </Route>
        <Route exact path={`${path}/daily/:date`}>
          {/* <DailyReport /> */}
        </Route>
        <Redirect to={`${path}/weekly`} />
      </Switch>
    </ReportDatesProvider>
  );
}

export default Administration;
