import React, { Fragment } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@apollo/client';
import moment from 'moment';

import { GET_HOUR_COUNT } from '../../../graphql/queries';
import useVisibilityChange from '../../../hooks/useVisibilityChange';

function TimeForDay({ date, unclockedTime }) {
  const { user } = useAuth0();
  const userId = user['https://cthomes/mongo_uid'];
  const { loading, data, refetch } = useQuery(GET_HOUR_COUNT, {
    variables: {
      userId,
      startDate: moment(date).startOf('day').toDate(),
      endDate: moment(date).endOf('day').toDate()
    }
  });

  useVisibilityChange(refetch);

  if (loading) return null;

  const timeFromQuery = data.hourCount.totalMilliseconds;
  const timeForDay = moment.duration(timeFromQuery + unclockedTime);
  const hours = timeForDay.hours() < 10 ? `0${timeForDay.hours()}` : timeForDay.hours();
  const minutes = timeForDay.minutes() < 10 ? `0${timeForDay.minutes()}` : timeForDay.minutes();
  const seconds = timeForDay.seconds() < 10 ? `0${timeForDay.seconds()}` : timeForDay.seconds();

  return (
    <Fragment>
      {`${hours}:${minutes}:${seconds}`}
    </Fragment>
  );
}

export default TimeForDay;
