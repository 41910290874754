import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useMutation, gql } from '@apollo/client';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import { GET_HOUR_COUNT } from '../../graphql/queries';
import { ADD_RECORD, UPDATE_RECORD } from '../../graphql/mutations';
import useLastRecord from '../../hooks/useLastRecord';

function ToggleStatusButton() {
  const { user } = useAuth0();
  const userId = user['https://cthomes/mongo_uid'];
  const { loading, data } = useLastRecord();
  const [addRecord] = useMutation(ADD_RECORD, {
    update(cache, { data: { createRecord: { record } } }) {
      const newRecordRef = cache.writeFragment({
        data: record,
        fragment: gql`
          fragment NewRecord on Record {
            _id
            owner_id
            owner_name
            timeIn
            timeOut
          }
        `
      });
      cache.modify({
        fields: {
          lastRecord() {
            return newRecordRef;
          },
          records(existingRecords = []) {
            return [newRecordRef, ...existingRecords];
          }
        }
      });
    }
  });
  const [updateRecord] = useMutation(UPDATE_RECORD, {
    refetchQueries: [
      {
        query: GET_HOUR_COUNT,
        variables: {
          userId,
          startDate: moment().startOf('day').valueOf(),
          endDate: moment().endOf('day').valueOf()
        }
      }
    ]
  });

  function clockIn() {
    addRecord({
      variables: {
        newRecord: {
          owner_id: userId,
          owner_name: user.name,
          timeIn: new Date(),
          timeOut: null
        }
      }
    });
  }

  function clockOut() {
    updateRecord({
      variables: {
        updatedRecord: {
          _id: data.lastRecord._id,
          type: 'out',
          timeOut: new Date()
        }
      }
    });
  }

  if (loading) return null;

  const isClockedIn = !data.lastRecord.timeOut ? true : false;

  return (
    <Row className="justify-content-center mb-2">
      <Col lg="4" md="6">
        {isClockedIn ? (
          <Button
            className="btn-block"
            onClick={clockOut}
          >Clock Out</Button>
        ) : (
            <Button
              className="btn-block"
              onClick={clockIn}
            >Clock In</Button>
          )}
      </Col>
    </Row>
  );
}

export default ToggleStatusButton;
