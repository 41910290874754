import gql from 'graphql-tag';

const ADD_RECORD = gql`
  mutation AddRecord($newRecord: CreateRecordInput) {
    createRecord(newRecord: $newRecord) {
      code
      success
      message
      record {
        _id
        owner_id
        owner_name
        timeIn
        timeOut
      }
    }
  }
`;

const UPDATE_RECORD = gql`
  mutation UpdateRecord($updatedRecord: UpdateRecordInput) {
    updateRecord(updatedRecord: $updatedRecord) {
      code
      success
      message
      record {
        _id
        timeIn
        timeOut
      }
    }
  }
`;

export {
  ADD_RECORD,
  UPDATE_RECORD
}