import React from 'react';
import moment from 'moment';
import DatePicker from 'react-date-picker';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import useReportDates from '../../../hooks/useReportDates';

function WeeklyDatePickers() {
  const { dates, changeDates } = useReportDates();

  function onStartChange(val) {
    const newEndDate = moment(val).add(6, 'days').endOf('day').toDate();
    changeDates({
      type: 'weekly',
      payload: {
        start: val,
        end: newEndDate
      }
    });
  }

  function onEndChange(val) {
    changeDates({
      type: 'weekly',
      payload: {
        end: moment(val).endOf('day').toDate()
      }
    });
  }

  return (
    <Row className="justify-content-center mb-1">
      <Col md={{ span: 'auto', offset: 'auto' }}>
        <span>Start: </span>
        <DatePicker
          calendarIcon={null}
          clearIcon={null}
          value={dates.weekly.start}
          onChange={onStartChange}
          locale="en-US"
        />
      </Col>
      <Col md={{ span: 'auto', offset: 'auto' }}>
        <span>End: </span>
        <DatePicker
          calendarIcon={null}
          clearIcon={null}
          value={dates.weekly.end}
          onChange={onEndChange}
          locale="en-US"
        />
      </Col>
    </Row>
  );
}

export default WeeklyDatePickers;
