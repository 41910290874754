import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import Nav from './Navigation';
import Switches from './Switches';

function App() {
  const { isAuthenticated, isLoading, user } = useAuth0();

  return (
    <div>
      <Nav />
      {!isLoading && (
        <Switches isAuthenticated={isAuthenticated} user={user} />
      )}
    </div>
  );
}

export default App;
