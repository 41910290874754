import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import moment from 'moment';
import Container from 'react-bootstrap/Container';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

import DailyReportTable from './DailyReportTable';
import TimeForDay from './TimeForDay';

function DailyReport() {
  const userId = useAuth0().user['https://cthomes/mongo_uid'];
  const date = parseInt(useParams().date);
  
  return (
    <Container fluid="md">
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/reports/weekly' }}>Weekly Report</Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/reports/user' }}>User Report</Breadcrumb.Item>
        <Breadcrumb.Item active>Daily Report</Breadcrumb.Item>
      </Breadcrumb>
      <h3>{moment(date).format('dddd, MMMM D, YYYY')}</h3>
      <DailyReportTable userId={userId} date={date} />
      <TimeForDay userId={userId} date={date} />
    </Container>
  );
}

export default DailyReport;
