import React from 'react';
import Container from 'react-bootstrap/Container';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

import WeeklyDatePickers from './WeeklyDatePickers';
import WeeklyDateButtons from './WeeklyDateButtons';
import WeeklyReportTable from './WeeklyReportTable';

function WeeklyReport() {
  return (
    <Container fluid="md">
      <Breadcrumb>
        <Breadcrumb.Item active>Weekly Report</Breadcrumb.Item>
      </Breadcrumb>
      <WeeklyDatePickers />
      <WeeklyDateButtons />
      <WeeklyReportTable />
    </Container>
  );
}

export default WeeklyReport;
