import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import Table from 'react-bootstrap/Table';

import { GET_RECORDS } from '../../graphql/queries';
import useVisibilityChange from '../../hooks/useVisibilityChange';

function DashboardTable() {
  const { user } = useAuth0();
  const userId = user['https://cthomes/mongo_uid'];
  const { loading, data, refetch } = useQuery(GET_RECORDS, {
    variables: {
      userId,
      startDate: moment().startOf('day').valueOf(),
      endDate: moment().endOf('day').valueOf()
    }
  });

  useVisibilityChange(refetch);

  if (loading) return <p>Loading...</p>;

  return (
    <Table>
      <thead>
        <tr>
          <th>Time In</th>
          <th>Time Out</th>
        </tr>
      </thead>
      <tbody>
        {data.records.map(record => (
          <tr key={record._id}>
            <td>{moment(record.timeIn).format('h:mm A')}</td>
            {moment(record.timeOut).isValid() && <td>{moment(record.timeOut).format('h:mm A')}</td>}
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

export default DashboardTable;
