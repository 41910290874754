import { useEffect, useRef } from 'react';

function useVisibilityChange(handler) {
  const savedHandler = useRef(handler);

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  function handleVisibilityChange() {
    if (document.hidden) return;
    else savedHandler.current();
  }

  function handleFocus() {
    savedHandler.current();
  }

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange, false);
    window.addEventListener('focus', handleFocus, false);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('focus', handleFocus);
    }
  }, [savedHandler]);
}

export default useVisibilityChange;
