import React from 'react';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';

import useReportDates from '../../../hooks/useReportDates';

function WeeklyDateButtons() {
  const { changeDates } = useReportDates();

  function onClick(numWeeks) {
    if (numWeeks === 0) {
      changeDates({
        type: 'weekly',
        payload: {
          start: moment().startOf('week').toDate(),
          end: moment().endOf('week').toDate()
        }
      });
    } else {
      changeDates({
        type: 'weekly',
        payload: {
          start: moment().startOf('week').subtract(numWeeks, 'week').toDate(),
          end: moment().endOf('week').subtract(numWeeks, 'week').toDate()
        }
      });
    }
  }

  return (
    <Row className="justify-content-center mb-3">
      <ButtonGroup>
        <Button variant="outline-primary" onClick={() => onClick(0)}>Current week</Button>
        <Button variant="outline-primary" onClick={() => onClick(1)}>Last week</Button>
        <Button variant="outline-primary" onClick={() => onClick(2)}>2 weeks ago</Button>
        <Button variant="outline-primary" onClick={() => onClick(3)}>3 weeks ago</Button>
        <Button variant="outline-primary" onClick={() => onClick(4)}>4 weeks ago</Button>
      </ButtonGroup>
    </Row>
  );
}

export default WeeklyDateButtons;
