import React, { Fragment } from 'react';
import { useQuery } from '@apollo/client';
import moment from 'moment';

import { GET_HOUR_COUNT } from '../../../graphql/queries';
import useReportDates from '../../../hooks/useReportDates';
import useUnclockedTime from '../../../hooks/useUnclockedTime';
import useVisibilityChange from '../../../hooks/useVisibilityChange';

function WeeklyTotal({ userId }) {
  const { dates: { weekly: dates } } = useReportDates();
  const unclockedTime = useUnclockedTime();
  const includeUnclockedTime = moment().isBetween(dates.start, dates.end);
  const { loading, data, refetch } = useQuery(GET_HOUR_COUNT, {
    variables: {
      userId,
      startDate: dates.start,
      endDate: dates.end
    }
  });

  useVisibilityChange(refetch);

  if (loading) return null;

  const total = includeUnclockedTime
    ? moment.duration(data.hourCount.totalMilliseconds + unclockedTime)
    : moment.duration(data.hourCount.totalMilliseconds);

  return (
    <Fragment>
      {`${total.hours() + total.days() * 24} h ${total.minutes()} m ${total.seconds()} s`}
    </Fragment>
  );
}

export default WeeklyTotal;
