import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Table from 'react-bootstrap/Table';

import WeeklyTotal from './WeeklyTotal';

function WeeklyReportTable() {
  const { user } = useAuth0();
  const userId = user['https://cthomes/mongo_uid'];
  
  return (
    <Table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Total Time</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <Link to="/reports/user">{user.name}</Link>
          </td>
          <td>
            <WeeklyTotal userId={userId} />
          </td>
        </tr>
      </tbody>
    </Table>
  );
}

export default WeeklyReportTable;
