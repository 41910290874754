import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@apollo/client';
import moment from 'moment';

import useUnclockedTime from '../../hooks/useUnclockedTime';
import useVisibilityChange from '../../hooks/useVisibilityChange';
import { GET_HOUR_COUNT } from '../../graphql/queries';

function TimeForDay() {
  const { user } = useAuth0();
  const unclockedTime = useUnclockedTime();
  const userId = user['https://cthomes/mongo_uid'];
  const { loading, data, refetch } = useQuery(GET_HOUR_COUNT, {
    variables: {
      userId,
      startDate: moment().startOf('day').valueOf(),
      endDate: moment().endOf('day').valueOf()
    }
  });
  
  useVisibilityChange(refetch);

  if (loading) return null;

  const timeFromQuery = data.hourCount.totalMilliseconds;
  const totalTimeForDay = moment.duration(timeFromQuery + unclockedTime);

  return (
    <p>
      {`Total time for today: ${totalTimeForDay.hours()} hours ${totalTimeForDay.minutes()} minutes ${totalTimeForDay.seconds()} seconds`}
    </p>
  );
}

export default TimeForDay;
